import { withTheme } from '@emotion/react'
import React from 'react'
import Dropdown from './Dropdown.styles'
import GlobalCss from './GlobalCss.styles'
import Hero from './Hero.styles'
import PackageCard from './PackageCard.styles'

const GlobalStyles = withTheme(() => (
  <>
    <GlobalCss />
    <PackageCard />
    <Dropdown />
    <Hero />
  </>
))

export default GlobalStyles
