import { css, Global, withTheme } from '@emotion/react'
import * as React from 'react'

const PackageCard = withTheme(({ theme }) => (
  <Global
    styles={css`
      .leshen-package-card {
        .wrapper {
          background-color: #f3ede0;
          border-radius: 16px;
          border-top: 0;
          box-shadow: none !important;

          .title {
            font-size: 24px;
            font-family: 'Verizon NHG Light' !important;
            color: ${theme.colors.primary.base.hex} !important;
          }

          .leshen-price-amount-wrapper {
            align-items: flex-end !important;

            .leshen-price-currency,
            .leshen-price-dollar,
            .leshen-price-change,
            .leshen-price-duration {
              font-family: 'Verizon NHG Light' !important;
            }
          }

          .leshen-price-currency {
            font-size: 45px;
          }

          .leshen-price-dollar,
          .leshen-price-change {
            position: relative;
            bottom: -5px;
          }

          .leshen-price-duration {
            align-self: unset !important;
            font-size: 14px !important;
          }

          .bullets {
            width: fill-available;
            padding: 16px;
            border-radius: 16px;
            background-color: ${theme.colors.white.base.hex};

            .list-item {
              div {
                margin: 0;
              }

              svg {
                display: none;
              }

              .leshen-brandy {
                b,
                strong {
                  font-family: 'Verizon NHG Bold' !important;
                }
              }
            }
          }
        }
      }
    `}
  />
))

export default PackageCard
