import { css, Global, withTheme } from '@emotion/react'
import * as React from 'react'

const Hero = withTheme(() => (
  <Global
    styles={css`
      .leshen-billboard {
        overflow: visible !important;
      }

      .leshen-price-change {
        span {
          color: unset !important;
        }
      }
    `}
  />
))

export default Hero
